import React, { FC, AnchorHTMLAttributes } from "react";
import { variants, sizes } from "./styles";
import classNames from "classnames";
import { t } from "@lingui/macro";
import { ButtonProps } from "./Button";

type Props = ButtonProps &
  AnchorHTMLAttributes<HTMLAnchorElement> & {
    ref?: React.Ref<HTMLAnchorElement>;
    disabled?: boolean;
  };

export const ButtonLink: FC<Props> = React.forwardRef(
  (
    {
      children,
      variant = "primary",
      size = "md",
      rounded,
      className,
      ...props
    },
    ref: React.Ref<HTMLAnchorElement>
  ) => {
    const classes = classNames(
      "inline-block font-medium text-center border-0 no-underline pointer whitespace-no-wrap px-6",
      variants[variant],
      sizes[size],
      className,
      {
        "opacity-50 text-white cursor-not-allowed pointer-events-none":
          props.disabled,
        "rounded-full": rounded,
      }
    );

    return (
      <a
        {...props}
        title={t({
          id: "common.link.title",
          message: "GoWithGuide - Private Tours & Local Tour Guides",
        })}
        className={classes}
        ref={ref}
      >
        <span className="whitespace-nowrap">{children}</span>
      </a>
    );
  }
);

ButtonLink.displayName = "ButtonLink";
