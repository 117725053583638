export const variants = {
  default: "bg-gray-200 text-gray-800 hover:bg-gray-300",
  white: "bg-white text-gray-700 hover:text-black",
  black: "bg-black text-white hover:shadow hover:bg-gray-800",
  primary: "bg-primary text-white hover:bg-primary-dark",
  secondary: "bg-secondary text-white hover:bg-secondary-dark",
  yellow: "bg-yellow-500 text-white hover:bg-yellow-700",
  blue: "bg-blue-600 text-white hover:bg-blue-800",
  yellow2: "bg-gwg_yellow text-white hover:opacity-80",
  green: "bg-green-700 text-white hover:bg-gray-700",
  orange: "bg-star text-white hover:bg-gray-700",
  "golden-yellow":
    "text-black bg-gwg_golden_yellow hover:bg-gwg_golden_yellow_dark",
  "primary-outline":
    "bg-transparent text-primary hover:text-primary-dark border border-primary",
  "secondary-outline":
    "bg-transparent text-secondary hover:text-secondary-dark border border-secondary",
  "default-outline":
    "bg-transparent text-gray-600 hover:text-black border border-gray-600",
  "white-outline":
    "bg-transparent  border border-white text-white hover:bg-black hover:bg-opacity-25",
};

export const sizes = {
  xs: "text-xs p-2 rounded",
  sm: "text-sm p-2 rounded",
  md: "text-md p-3 rounded",
  lg: "text-lg p-4 rounded",
  xl: "text-xl px-6 py-5 rounded",
};
