import classNames from "classnames";
import React, { FC, HTMLAttributes } from "react";

export const Container: FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  className = "",
  ...props
}) => {
  const classes = classNames("px-4 md:px-8 mx-auto", className, {
    "py-24": !className.includes("py-"),
    "max-w-6xl": !className.includes("max-w") && !className.includes("w-full"),
  });
  return (
    <div className={classes} {...props}>
      {children}
    </div>
  );
};
