import { NextSeoProps } from "next-seo";

interface SEORoutes {
  [key: string]: NextSeoProps;
}

export const routes: SEORoutes = {
  "/japan/tokyo": {
    description:
      "Top Tokyo Tours: See reviews and photos of the best Tokyo private tours and licensed local guides. Plan a day trip and request an itinerary.",
  },
  "/japan/kyoto": {
    description:
      "Kyoto Private Tours: Enjoy the history and culture of Kyoto with a licensed local tour guide. Plan a day trip, see reviews and photos.",
  },
  "/japan/osaka": {
    description:
      "Best Osaka Private Tours: Find things to do, plan a day trip, see reviews and photos, and request an itinerary from local tour guides in Osaka.",
  },
  "/japan/mount-fuji": {
    description:
      "Mt Fuji Private Tours: Discover nature, the best photo spots, and things to do in Mt Fuji with a local guide. See reviews and plan a day trip.",
  },
  "/japan/hakone": {
    description:
      "Hakone Private Tours and Local Guides: Enjoy hot springs, museums, and local things to do with a private tour guide. See reviews and photos.",
  },
  "/japan/tokyo/tours": {
    description:
      "Top Tokyo Tours: Explore Shinjuku, Asakusa, Tokyo Skytree and Imperial Palace. Discover Tokyo in a private tour, day trip, night tour, or food tour.",
  },
  "/japan/kyoto/tours": {
    description:
      "Best Kyoto Private Tours: Learn the history of Kiyomizudera, Golden Pavilion, Gion, and Fushimi Inari. Explore Kyoto in a day trip or city tour.",
  },
  "/japan/osaka/tours": {
    description:
      "Top Osaka Private Tours: Explore Osaka Castle, Dotonbori, and Umeda Sky Building. Enjoy Osaka in a food tour, city tour, day trip, or night tour.",
  },
  "/japan/mount-fuji/tours": {
    description:
      "Best Mount Fuji Tours: Discover top photo spots of Mount Fuji, from Kawaguchiko, to Arakurayama Sengen Park, to Fuji Five Lakes in a private tour.",
  },
  "/japan/hakone/tours": {
    description:
      "Top Hakone Tours: Visit onsen hot springs, museums, Lake Ashi, and Owakudani with a local guide in a private tour, day trip, or walking tour.",
  },
};
