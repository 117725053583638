import { SITE_URL } from "@constants";

const getUrl = ({
  asPath,
  keepQueryParams,
}: {
  asPath: string;
  keepQueryParams: boolean;
}) => {
  const path = asPath === "/" ? "" : asPath;

  if (keepQueryParams) {
    return `${SITE_URL}${path}`;
  }

  const basePath = path.includes("?") ? path.split("?")[0] : path;
  const pathname = basePath.endsWith("/top") ? basePath.slice(0, -4) : basePath;

  return `${SITE_URL}${pathname}`;
};

export default getUrl;
