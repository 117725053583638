import React, { FC, ButtonHTMLAttributes } from "react";
import { variants, sizes } from "./styles";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";

export interface ButtonProps {
  variant?: keyof typeof variants;
  rounded?: boolean;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  fontLight?: boolean;
}

export const Button: FC<
  ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>
> = ({
  children,
  variant = "primary",
  size = "md",
  rounded,
  className,
  fontLight,
  ...props
}) => {
  const classes = twMerge(
    classNames(
      "text-center no-underline pointer whitespace-no-wrap",
      variants[variant],
      sizes[size],
      {
        "opacity-30 cursor-not-allowed": props.disabled,
        "rounded-full": rounded,
        "font-thin": fontLight,
        "font-medium": !fontLight,
      }
    ),
    className || ""
  );

  return (
    <button {...props} className={classes}>
      {children}
    </button>
  );
};
