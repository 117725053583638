import React, { FC } from "react";
import NextHead from "next/head";
import { NextSeo, NextSeoProps } from "next-seo";
import { SITE_URL } from "@constants";
import { useRouter } from "next/router";
import { routes } from "@root/seo";
import getUrl from "@helpers/getUrl";

interface IProps extends NextSeoProps {
  keepQueryParams?: boolean;
}

const getOgUrl = (asPath: string): string => {
  // Use SITE_URL for root or index paths, otherwise append path to SITE_URL
  if (asPath === "/" || asPath === "/index") {
    return SITE_URL;
  }
  return `${SITE_URL}${asPath}`;
};

const Head: FC<IProps> = (props) => {
  const { asPath } = useRouter();
  const routeSEO = routes[asPath] || {};

  let canonical = props.canonical || asPath;
  canonical = canonical.toLowerCase();

  const ogUrl = getOgUrl(asPath);

  return (
    <>
      <NextSeo
        {...props}
        {...routeSEO}
        canonical={getUrl({
          asPath: canonical,
          keepQueryParams: props.keepQueryParams || false,
        })}
        openGraph={{
          type: "website",
          url: ogUrl, // Use the new ogUrl here
          title: props.title,
          description: props.description,
          site_name: props.title,
          ...props.openGraph,
        }}
      />
      <NextHead>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </NextHead>
    </>
  );
};

export default Head;
